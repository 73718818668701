import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { BlogTitle } from "@/components/BlogTitle/BlogTitle.tsx";
import { Page } from "@/components/Page.tsx";
import "@/styles/Blog.scss";
export const _frontmatter = {
  "title": "Vehicle Clearance",
  "path": "/blog/2019/Mar/vehicle-clearance",
  "date": "2019-03-17T00:00:00.000Z",
  "description": "An explanation of vehicle clearance templates used by Australian Standards 2890.1."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Page mdxType="Page">
      <BlogTitle title={props.pageContext.frontmatter.title} datePublished={props.pageContext.frontmatter.date} mdxType="BlogTitle" />
      <p>{`This post is a deviation from my usual. I'll be discussing the aspects of the `}<strong parentName="p">{`Australian Standards
for Off-Street Car Parking`}</strong>{` (AS 2890.1 - 2004). Namely, the vehicle clearance templates as defined
within the standard. The motivation for writing this post came from trying to understand how the
template is defined in order to use it accurately and, if necessary, to modify it in a justifiable
and reasoned manner.`}</p>
      <h2>{`There's a standard for this?`}</h2>
      <p>{`Yes really!`}</p>
      <p>{`There is a standard that defines a `}<em parentName="p">{`Vehicle Template`}</em>{` that designers use to test vehicle paths for
ground clearance. The template is a geometric shape of the under side of a design vehicle. Since
real vehicles come in all sorts of shapes and sizes, the standard provides two vehicle templates;
the `}<strong parentName="p">{`B85`}</strong>{` and `}<strong parentName="p">{`B99`}</strong>{` templates. These templates cover a chosen percentage of recorded passenger
vehicles sold during a certain time period. For the B85 its 85% of passenger vehicles, and for the
B99 it is 99.8%.`}</p>
      <p>{`These statistics are detailed in the standard itself and there is an added level of conservativeness
built into the template definition as a factor of safety and uniformity between the two templates.`}</p>
      <h2>{`How is the Vehicle Template defined?`}</h2>
      <p>{`The geometry of the vehicle template can be derived from six key parameters;`}</p>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}></th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Front Overhang`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Distance from front wheel center to front of vehicle`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Wheel Base`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Distance between front and rear wheel centers`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Rear Overhang`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Distance from rear wheel center to rear of vehicle`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Clearance Height`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Distance from underside to ground (as a flat line)`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Approach Angle`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Max angle of change of the ground the vehicle can enter without scraping`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}><strong parentName="td">{`Departure Angle`}</strong></td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Max angle of change of the ground the vehicle can leave without scraping`}</td>
          </tr>
        </tbody>
      </table>
      <p>{`Here's a little diagram of all this 🙂`}</p>
      <p><img parentName="p" {...{
          "src": "https://firebasestorage.googleapis.com/v0/b/vivekr-2d3dc.appspot.com/o/vehicle-clearance-template.png?alt=media&token=7a6581ed-c833-4037-933a-23b62dd76781",
          "alt": "..."
        }}></img></p>
      <h2>{`What about the wheel radius?`}</h2>
      <p>{`Interestingly, the standard does not define a wheel radius to employ in defining the clearance
template. It defines a "knife edge" where the base of the wheels should be. The intention is for the
user to place the front and rear knife edges on the test vehicle path surface and see if the
template intersects the ground. This allows the template to get unrealistically close to the test
surface.`}</p>
      <p>{`A more realistic vehicle clearance template should define a non-zero, but reasonably conservative,
wheel radius. I opt for a wheel radius of 300mm that is conservative and also suitable for the
real-life vehicle the B85 and B99 is likely to match.`}</p>
      <p>{`Choosing a non-zero wheel radius also impacts the derivation of the geometry of the template. The
Approach Angle and Departure Angle previous taken from the knife-edge must now be tangent to the
wheel. This results in a slightly enlarged template geometry as the approach and departure angle
lines are pushed away from the wheel centers.`}</p>
      <h2>{`Putting it all together`}</h2>
      <p>{`No fun in reading all this if you can't `}<em parentName="p">{`take it for a ride`}</em>{` right? I built a quick little app with
`}<strong parentName="p">{`ReactJS`}</strong>{` and `}<strong parentName="p">{`PaperJS`}</strong>{` (a great Vector Graphics JS Library).`}</p>
      <p>{`Test drive it `}<a parentName="p" {...{
          "href": "https://vehicle-clearance.surge.sh"
        }}>{`here`}</a>{` 😉`}</p>
    </Page>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      